import { font } from "@/lib/theme/font";
import { Text } from "../Text";

export const Caption = (props: { value: string }) => {
  return (
    <Text
      t="caption"
      css={`
        span,
        p {
          ${font("dark-mode")["caption"]}
        }
      `}
    >
      {props?.value}
    </Text>
  );
};
