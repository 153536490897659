import { TinaMarkdown, TinaMarkdownContent } from "tinacms/dist/rich-text";
import {
  SuperScript,
  Caption,
  Uppercase,
  ImageSet,
  Spacing,
  Form,
} from "./Templates";

export const markdownComponents = {
  SuperScript,
  Caption,
  Uppercase,
  ImageSet,
  Spacing,
  Form,
};

export default function Markdown({
  children,
}: {
  children?:
    | (TinaMarkdownContent | TinaMarkdownContent[])
    | string
    | null
    | undefined;
}) {
  return typeof children === "string" ? (
    <>{children}</>
  ) : !!children ? (
    <>
      <TinaMarkdown content={children} components={markdownComponents} />
    </>
  ) : (
    <></>
  );
}

export function ArticleMarkdown({
  children,
}: {
  children?:
    | (TinaMarkdownContent | TinaMarkdownContent[])
    | string
    | null
    | undefined;
}) {
  return typeof children === "string" ? (
    <>{children}</>
  ) : !!children ? (
    <div className="article">
      <TinaMarkdown content={children} components={markdownComponents} />
    </div>
  ) : (
    <></>
  );
}
