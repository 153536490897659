import styled, { css } from "styled-components";

export const TextLink = styled.div`
${(t) => t.theme.font.caption}
text-decoration: none;
width: fit-content;
overflow: hidden;
::after{
  content: '';
  display: block;
  height: 1px;
  background-color: ${(t) => t.theme.colors["text/secondary"]};
  transform: scaleX(0);
  transform-origin: -50% 0%;
  transition: transform 0.5s ease-out;
  width: 200%;
}
:hover::after{
  transform: scaleX(.68);
  bottom: 0; left:0;
}

`;