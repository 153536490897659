import { TextLink } from "@/components/shared/TextLink";
import { desktop, tabletDown } from "@/lib";
import Image from "next/image";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Container = styled.div<{
    color?: string;
    $visible?: boolean;
    $position?: string;
    global?: GlobalPartsFragment;
  }>`
    width: 100vw;
    display: flex;
    position: ${(t) => t.$position || "fixed"};
    justify-content: space-between;
    align-items: center;
    padding: 1rem ${(d) => d.global?.sidePadding?.desktop || "calc(100vw / 12)"};
    top: 0;
    left: 0;
    z-index: 100;
    height: 80px;
    background-color: ${(t) =>
      t.color
        ? t.theme.colors[t.color as keyof typeof t.theme.colors]
        : "unset"};
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate(0, ${(t) => (t.$visible ? 0 : -100)}%);
  `,
  MenuItem = styled.div`
    z-index: 101;
    position: static;
  `,
  SubMenuContainer = styled.div<{
    color?: string;
    $visible?: boolean;
    position?: string;
  }>`
    width: 100vw;
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 calc(100vw / 12) 0 calc(100vw / 12);
    top: 0px;
    padding-top: 80px;
    left: 0;
    min-height: 260px;
    background-color: ${(t) =>
      t.color
        ? t.theme.colors[t.color as keyof typeof t.theme.colors]
        : "unset"};
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate(
      0,
      ${(t) => (t.$visible ? "calc( 0px - 0% )" : "calc( 80px - 100% )")}
    );
  `,
  SubMenuItem = styled.div<{ $visible?: boolean; $submenu?: boolean }>`
    position: absolute;
    left: 0;
    visibility: ${(t) => (t.$visible ? "visible" : "hidden")};
    padding-top: 1rem;
    min-width: 100%;
    width: 50vw;
    max-width: 100vw;
    padding: 1rem 0 2rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    overflow: hidden;
    width: 100vw;
    transition: max-height 1s cubic-bezier(0.19, 1, 0.22, 1);
    max-height: ${(t) => (t.$submenu ? "185px" : "0px")};
  `,
  Logo = styled.img`
    width: 142px;
    ${desktop(css`
      margin-right: 4rem;
      margin-bottom: 0px;
    `)}
    cursor: pointer;
    z-index: 101;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
  `,
  Links = styled.div`
    display: flex;
    align-items: center;
    z-index: 101;
    ${tabletDown(css`
      flex: 1 1 auto;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 1.5rem;
    `)}
  `,
  StyledLink = styled(TextLink)`
    ${(t) => t.theme.font.caption}
    color: ${(t) => t.theme.colors["text/primary"]};

    height: 100%;

    align-items: center;
    justify-content: center;
    padding: 0 2rem 0 0;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
  `,
  Button = styled.button`
    height: 40px;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: #fff;
    :hover {
      background-color: #e6e6e6;
    }
    ${(t) => t.theme.font.button}
    color: #2E2B26;
    ${tabletDown(
      css`
        width: 100%;
        margin-top: 1.5rem;
        height: 3.5rem;
        margin-left: 0;
      `
    )}
  `,
  StyledImage = styled(Image)<{ $visible?: boolean }>`
    object-fit: cover;
    transform: scale(1);
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    &:hover {
      transform: scale(1.02);
    }
  `,
  SidePanel = styled.div<{ open?: boolean }>`
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    padding-top: calc(80px + 2rem) !important;
    z-index: 99;
    background-color: ${(t) => t.theme.colors["background/bg-0"]};
    transform: ${(t) => (t.open ? "translate(0,0)" : "translate(100%,0)")};
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;
  `;
