import {
  Answer,
  Question,
  QuestionBox,
} from "@/components/Blocks/FAQ/FAQ4/styles";
import { SafeLink } from "@/components/shared/SafeLink";
import { Divider, Text } from "@/components/shared/Text";
import {
  DesktopOnly,
  MobileOnly,
  TabletDown,
  TabletUp,
  mobile,
  tabletUp,
} from "@/lib";
import { useState } from "react";
import { css, useTheme } from "styled-components";
import {
  GlobalPartsFragment,
  LayoutFooterFooter4,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import {
  Column,
  Container,
  Heading,
  Icon,
  Links,
  StyledLink,
  Wrapper,
} from "./styles";

export default function Footer4({
  data,
  global,
}: {
  data: LayoutFooterFooter4;
  global?: GlobalPartsFragment;
}) {
  const theme = useTheme();
  const [menuField, setMenuField] = useState<string | null | undefined>(null);

  return (
    <div>
      <DesktopOnly>
        <Wrapper data-tina-field={tinaField(data)} global={global}>
          <Container style={{ paddingBottom: "16rem" }} global={global}>
            <Links>
              {(data.columns || []).map((col, i) => (
                <Column
                  key={i + (col?.title || "")}
                  data-tina-field={col && tinaField(col)}
                >
                  <SafeLink href={col?.link}>
                    <Heading data-tina-field={col && tinaField(col, "title")}>
                      {col?.title || ""}
                    </Heading>
                  </SafeLink>
                  {(col?.links || []).map((v, n) => (
                    <SafeLink key={n + (v?.name || "")} href={v?.link}>
                      <StyledLink data-tina-field={v && tinaField(v, "name")}>
                        {v?.name}
                      </StyledLink>
                    </SafeLink>
                  ))}
                </Column>
              ))}
            </Links>
          </Container>
          <Divider c="elements/gray-1" />
          <SocialMedia {...{ data, global }} />
        </Wrapper>
      </DesktopOnly>
      <TabletDown>
        <Wrapper data-tina-field={tinaField(data)} global={global}>
          {data.columns
            ?.filter((v) => v?.title && v.title !== " ")
            ?.map((v, i) => (
              <QuestionBox
                style={{ width: "100%", flex: "1 1 auto" }}
                data-tina-field={v && tinaField(v)}
                key={i}
                onClick={() => {
                  if (v?.links && v.links.length) {
                    if (menuField === v?.title) {
                      setMenuField(null);
                    } else {
                      setMenuField(v?.title);
                    }
                  }
                }}
              >
                {v?.links && v.links.length ? (
                  <Question
                    $open={menuField === v?.title}
                    $grey={menuField !== v?.title && menuField !== null}
                  >
                    <Text
                      t="headline3"
                      data-tina-field={tinaField(v, "title")}
                      m="0"
                    >
                      {v?.title}
                    </Text>
                    <img
                      alt="Open submenu"
                      src="/icons/chevron-down.svg"
                      width="24"
                      height="24"
                      style={{
                        transform:
                          menuField === v?.title ? "scaleY(-1)" : "scaleY(1)",
                      }}
                    />
                  </Question>
                ) : (
                  <SafeLink href={v?.link}>
                    <Question $open={undefined} $grey={false}>
                      <Text
                        t="headline3"
                        data-tina-field={v && tinaField(v, "title")}
                        m="0"
                      >
                        {v?.title}
                      </Text>
                      <img
                        alt="Open submenu"
                        src="/icons/chevron-down.svg"
                        width="24"
                        height="24"
                        style={{
                          transform: "rotate(-90deg)",
                        }}
                      />
                    </Question>
                  </SafeLink>
                )}
                {v?.links && v.links.length && (
                  <Answer open={menuField === v?.title}>
                    <Column>
                      {[
                        { link: v.link, name: "Overview" },
                        ...(v?.links || []),
                      ].map((d, n) => (
                        <SafeLink key={n} href={d?.link}>
                          <StyledLink
                            data-tina-field={d && tinaField(d)}
                            style={{
                              paddingTop: "0.5rem",
                              color: theme.colors["text/secondary"],
                            }}
                          >
                            {d?.name}
                          </StyledLink>
                        </SafeLink>
                      ))}
                    </Column>
                    <br />
                    <br />
                  </Answer>
                )}
                <Divider c="elements/gray-1" mt="0" mb="0" />
              </QuestionBox>
            ))}
          <SocialMedia {...{ data, global }} />
        </Wrapper>
      </TabletDown>
    </div>
  );
}

function SocialMedia({
  data,
  global,
}: {
  data: LayoutFooterFooter4;
  global?: GlobalPartsFragment;
}) {
  return (
    <Container style={{ flexDirection: "column" }} global={global}>
      <div style={{ alignSelf: "start", marginTop: "1.5rem" }}>
        <Links data-tina-field={tinaField(data, "socialMediaLinks")}>
          {Object.entries(data.socialMediaLinks || {})
            .filter(
              ([key, val]) =>
                !!val &&
                key !== "__typename" &&
                key !== "__meta__" &&
                key !== "_tina_metadata" &&
                key !== "_content_source"
            )
            .map(([key, val], i) => (
              <SafeLink key={i} href={val}>
                <span
                  data-tina-field={
                    data.socialMediaLinks &&
                    tinaField(data.socialMediaLinks, key as any)
                  }
                >
                  <Icon
                    src={`/icons/social/${key}.svg`}
                    alt={`Link to the ${key} of the company`}
                    width="32"
                    height="32"
                  />
                </span>
              </SafeLink>
            ))}
        </Links>
      </div>

      <div
        css={`
          display: flex;
          margin-top: 1rem;
          width: 100%;
          ${mobile(
            css`
              flex-wrap: wrap;
            `
          )}
        `}
      >
        <TabletUp>
          <div
            css={`
              margin-right: 4rem;
              justify-content: flex-start;
              display: 1 1 auto;
              width: fit-content;
              ${tabletUp(
                css`
                  white-space: nowrap;
                `
              )}
            `}
          >
            <Text data-tina-field={tinaField(data, "copyright")}>
              {data.copyright}
            </Text>
          </div>
        </TabletUp>
        <div
          data-tina-field={tinaField(data, "footerLinks")}
          css={`
            display: flex;
            margin-left: auto;
            ${mobile(
              css`
                flex-direction: column;
                flex: 0 0 100vw;
                margin-top: 1rem;
              `
            )}
          `}
        >
          {data.footerLinks?.map((v, i) => (
            <SafeLink href={v?.link} key={i}>
              <StyledLink data-tina-field={v && tinaField(v)}>
                {v?.title}
              </StyledLink>
            </SafeLink>
          ))}
        </div>
        <MobileOnly>
          <div
            css={`
              margin-right: 4rem;
              justify-content: flex-start;
              display: 1 1 auto;
              width: fit-content;

              margin-top: 1rem;
            `}
          >
            <Text data-tina-field={tinaField(data, "copyright")}>
              {data.copyright}
            </Text>
          </div>
        </MobileOnly>
      </div>
    </Container>
  );
}
Footer4.blockName = "LayoutFooterFooter4";
