export const SuperScript = (props: { value: string; link?: string }) => {
  return (
    <sup
      css={`
        vertical-align: top;
        font-size: 0.6rem;
        margin-top: -0.2rem;
        line-height: 0.8rem;
        cursor: pointer;
        > a {
          vertical-align: top;
          font-size: 0.6rem;
          margin-top: -0.2rem;
          line-height: 0.8rem;
          cursor: pointer;
        }
      `}
    >
      {props?.link ? (
        <a href={props.link}>{props.value}</a>
      ) : !isNaN(Number(props?.value)) ? (
        <a href={`#${props?.value}`}>{props.value}</a>
      ) : (
        props?.value
      )}
    </sup>
  );
};
