import { font } from "@/lib/theme/font";
import { Text } from "../Text";

export const Uppercase = (props: { value: string }) => {
  return (
    <Text
      t="uppercase"
      css={`
        span,
        p {
          ${font("dark-mode")["uppercase"]}
        }
      `}
    >
      {props?.value}
    </Text>
  );
};
