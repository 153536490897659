import { css } from "styled-components";
import { ImageOrVideoSet } from "../ImageOrVideo";

export const ImageSet = (props: {
  image: string;
  alt: string;
  caption?: string;
  imageSet?: {
    desktopImage?: string;
    tabletImage?: string;
    mobileImage?: string;
  };
  width?: string;
  height?: string;
  verticalAlign?: string;
  horizontalAlign?: string;
  objectFit?: string;
}) => {
  return (
    <>
      <figure
        css={`
          display: contents;
        `}
      >
        <ImageOrVideoSet
          imageSet={props.imageSet}
          src={props.image}
          alt={props.alt || ""}
          css={`
            object-position: center;
            margin: 1rem 0;
            display: block;
            width: fit-content;
            position: relative !important;
            ${props.width &&
            css`
              width: ${props.width} !important;
            `}
            ${props.height &&
            css`
              height: ${props.height} !important;
            `}
        ${props.verticalAlign &&
            css`
              align-self: ${props.verticalAlign} !important;
            `}
        ${props.horizontalAlign &&
            (props.horizontalAlign === "start"
              ? css`
                  margin-right: auto !important;
                `
              : props.horizontalAlign === "end"
              ? css`
                  margin-left: auto !important;
                `
              : css`
                  margin-right: auto !important;
                  margin-left: auto !important;
                `)}
        ${props.objectFit &&
            css`
              object-fit: ${props.objectFit} !important;
            `}
        height: unset;
          `}
        />
        {props?.caption && <figcaption>{props.caption}</figcaption>}
      </figure>
    </>
  );
};
