import { TextLink } from "@/components/shared/TextLink";
import { desktop, mobile, tablet, tabletDown } from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const Wrapper = styled.div<{ global?: GlobalPartsFragment }>`
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    ${desktop(
      css`
        align-items: center;
        padding: flex-start;
      `
    )}
    ${(t) =>
      tablet(
        css`
          align-items: center;
          padding: 2rem ${t.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          align-items: center;
          padding: 2rem ${t.global?.sidePadding?.mobile || "2rem"};
        `
      )}
    min-height: 200px;
    background-color: ${(t) => t.theme.colors["background/bg-0"]};
    overflow: hidden;
  `,
  Container = styled.div<{ global?: GlobalPartsFragment }>`
    ${(t) =>
      desktop(
        css`
          width: 100vw;
          padding: 1rem ${t.global?.sidePadding?.desktop || "calc(100vw / 12)"};
        `
      )}
    ${tabletDown(
      css`
        width: 100%;
        padding: 2rem 0;
      `
    )}
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background-color: ${(t) => t.theme.colors["background/bg-0"]};
  `,
  Links = styled.div`
    display: flex;
    flex: 1 1 auto;
  `,
  Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
  `,
  Heading = styled(TextLink)`
    ${(t) => t.theme.font.uppercase}
    color: ${(props) => props.theme.colors["text/primary"]};
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    padding: 12px 1rem 12px 0;
    text-transform: none;
    ::after {
      height: 2px; //Make underline bigger
    }
  `,
  StyledLink = styled(TextLink)`
    ${(t) => t.theme.font.caption}

    align-items: center;

    justify-content: center;
    padding: 4px 1rem 4px 0;
    color: ${(t) => t.theme.colors["text/secondary"]};
  `,
  Title = styled.div`
    ${(t) => t.theme.font.headline3}
  `,
  Copyrights = styled.div`
    ${(t) => t.theme.font.caption}
    height: 100%;
    align-items: center;
    justify-content: start;
    padding: 4px 0rem;
    margin-top: 1rem;
    color: ${(t) => t.theme.colors["text/secondary"]};
  `,
  Button = styled.button`
    height: 40px;
    padding: 0 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-1"]};
    :hover {
      background-color: ${(t) => t.theme.colors["background/bg-2"]};
    }
    ${(t) => t.theme.font.button}
  `,
  Icon = styled.img`
    width: 32px;
    margin-right: 2rem;
    cursor: pointer;
  `;
