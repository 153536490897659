import { Text } from "@/components/shared/Text";
import {
  desktop,
  mobile,
  position,
  PositioningParams,
  tablet,
  tabletDown,
} from "@/lib";
import styled, { css } from "styled-components";
import { GlobalPartsFragment } from "tina/__generated__/types";

export const TopWrapper = styled.div<{
    positioning?: PositioningParams | null;
  }>`
    ${(t) => t.positioning && position(t.positioning)}
  `,
  Wrapper = styled.div<{ global?: GlobalPartsFragment }>`
    width: 100vw;
    position: relative;
    display: flex;
    background-color: ${(p) => p.theme.colors["background/bg-0"]};
    background-position: center;
    background-size: cover;
    ${(t) =>
      desktop(
        css`
          padding: 0 ${t?.global?.sidePadding?.desktop || "calc(100% / 12)"};
        `
      )}
    ${(t) =>
      tablet(
        css`
          padding: 0 ${t?.global?.sidePadding?.tablet || "2rem"};
        `
      )}
    ${(t) =>
      mobile(
        css`
          padding: 0 ${t?.global?.sidePadding?.mobile || "2rem"};
        `
      )}
    box-sizing: border-box;
    justify-content: start;
    overflow: hidden;
    grid: 2rem 2rem;
    > div {
      flex: 1 1 100%;
    }
    a {
      cursor: pointer;
    }
  `,
  Block = styled.div`
    flex: 1 1 100%;
    min-width: 230px;
    flex-direction: column;
    display: flex;
    text-align: start;
    justify-content: start;
    position: relative;
    overflow: hidden;
    img {
      margin-left: 1rem;
    }
  `,
  InputBlock = styled.div`
    display: flex;
    flex-direction: row;
  `,
  Button = styled.button`
    height: 40px;
    padding: 0 2rem;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-1"]};
    :hover {
      background-color: ${(t) => t.theme.colors["background/bg-2"]};
    }
    ${(t) => t.theme.font.button}
  `,
  Input = styled.input`
    height: 40px;
    flex: 1 1 auto;
    padding: 0 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    cursor: pointer;
    border: 1px solid ${(t) => t.theme.colors["elements/gray-2"]};
    background-color: ${(t) => t.theme.colors["background/bg-0"]};
    color: ${(t) => t.theme.colors["text/primary"]};
    :hover {
      background-color: ${(t) => t.theme.colors["background/bg-1"]};
    }
    :active {
      background-color: ${(t) => t.theme.colors["background/bg-2"]};
    }
    :focus {
      outline: none;
    }
    ${(t) => t.theme.font.button}
  `,
  QuestionBox = styled.div``,
  Question = styled.div<{ $open?: boolean; $grey?: boolean }>`
    padding: 1.5rem 0 1.3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    ${(t) =>
      t.$grey &&
      css`
        h1,
        h2,
        h3 {
          color: grey;
        }
      `}
    transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1);
    &:hover {
      background-color: rgba(255, 255, 255, 0.02);
      img {
        ${(t) =>
          t.$open !== undefined && t.$open !== null && t.$open === false
            ? `transform: scaleY(0.8) !important;`
            : `transform: scaleY(-0.8) !important;`}
      }
    }
    cursor: pointer;
    span,
    img {
      pointer-events: none;
      user-select: none;
      transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    }
  `,
  Answer = styled.div<{ open?: boolean; maxHeight?: number }>`
    transition: max-height 1s cubic-bezier(0.19, 1, 0.22, 1);
    ${(p) =>
      p.open
        ? css`
            max-height: ${p.maxHeight ? `${p.maxHeight}px` : "50vh"};
          `
        : css`
            max-height: 0vh;
          `}
    overflow: hidden;
    /* padding-bottom: 2rem; */
  `,
  TitleBlock = styled(Block)`
    ${tablet(
      css`
        max-width: 30vw;
      `
    )}
  `,
  TitleText = styled(Text)`
    ${tablet(
      css`
        display: none;
      `
    )}
  `,
  ContainerBlock = styled(Block)<{ bodyWidth?: string }>`
    ${(t) =>
      desktop(
        css`
          padding-bottom: 0;
          flex: 1 1 ${t.bodyWidth || "100%"} !important;
        `
      )}
    ${tabletDown(
      css`
        padding-bottom: 0;
      `
    )}
  `;
