import { Fragment, ReactNode } from "react";
import styled from "styled-components";
import type {
  GlobalPartsFragment,
  PageQueryQuery,
} from "tina/__generated__/types";
import { Footer4 } from "./Footer";
import { Header1 } from "./Header";

const footers = [Footer4];

const headers = [Header1];

const Main = styled.main`
  width: 100vw;
  overflow-x: hidden;
  min-height: 50vh;
`;

export const Layout = (
  props: {
    layout?:
      | PageQueryQuery["global"]["pageLayout"]
      | PageQueryQuery["global"]["articleLayout"]
      | null;
  } & { children?: ReactNode } & {
    global?: GlobalPartsFragment;
  } & { pageId?: string }
) => {
  return (
    <Fragment>
      <header>
        {(props.layout?.header || []).map(function (block, i) {
          const Component = headers.find(
            (v) => v.blockName === block?.__typename
          );

          if (block?.__typename && Component) {
            return (
              <Component
                key={i + block.__typename}
                data={block as any}
                global={props.global}
                pageId={props.pageId}
              />
            );
          } else {
            return null;
          }
        })}
      </header>
      <Main>{props.children}</Main>
      <footer>
        {(props.layout?.footer || []).map(function (block, i) {
          const Component = footers.find(
            (v) => v.blockName === block?.__typename
          );

          if (block?.__typename && Component) {
            return (
              <Component
                key={i + block.__typename}
                data={block as any}
                global={props.global}
              />
            );
          } else {
            return null;
          }
        })}
      </footer>
    </Fragment>
  );
};
