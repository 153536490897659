import Markdown from "@/components/shared/Markdown";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useTheme } from "next-themes";
import { Fragment, useState } from "react";
import { tinaField } from "tinacms/dist/react";
import { Text } from "../../../shared/Text";
import { Area, Button, Input } from "./styles";

export function ContactForm({
  data,
  className,
}: {
  data: {
    formName?: string | null;
    formFields?:
      | ({
          placeholder?: string | null;
          fieldType?: string | null;
          fieldName?: string | null;
          required?: boolean | null;
          textArea?: boolean | null;
          textAreaRows?: number | null;
          options?: (string | null)[] | null;
          withOtherOption?: boolean | null;
        } | null)[]
      | null;
    disclaimer?: string | null;
    buttonText?: string | null;
    receivers?: (string | null)[] | null;
  };
  className?: string;
}) {
  const [state, setState] = useState(
    data?.formFields?.reduce((acc, v) => {
      if (!v) return acc;
      return {
        ...acc,
        [v.fieldName || ""]: "",
      };
    }, {} as { [key: string]: string }) || {}
  );

  const [status, setStatus] = useState<
    undefined | "loading" | "error" | "success"
  >();

  const { themes, forcedTheme, resolvedTheme, systemTheme, theme, setTheme } =
    useTheme();
  const [other, setOther] = useState(false);
  return (
    <div {...(className ? { className } : {})}>
      <form
        name={data.formName || "Contact"}
        action=""
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          setStatus("loading");
          try {
            const res = await fetch("/api/contact", {
              method: "POST",
              body: JSON.stringify({
                ...state,
                formName: data.formName || "Contact",
                receivers: data.receivers?.filter((v) => !!v) || [],
              }),
            });
            setStatus("success");
            setState(
              data?.formFields?.reduce((acc, v) => {
                if (!v) return acc;
                return {
                  ...acc,
                  [v.fieldName || ""]: "",
                };
              }, {} as { [key: string]: string }) || {}
            );
          } catch (err) {
            console.log(err);
            setStatus("error");
          }
        }}
      >
        <>
          <Text t="headline2" data-tina-field={tinaField(data, "formName")}>
            {data.formName}
          </Text>
          {data.formFields?.map((v, i) => {
            if (!v) return <Fragment key={i} />;
            if (v.textArea) {
              return (
                <Area
                  key={i}
                  rows={v?.textAreaRows || 5}
                  placeholder={(v.placeholder || "") + (v.required ? "*" : "")}
                  id={v.fieldName || ""}
                  name={v.fieldName || ""}
                  required={!!v.required}
                  value={state[v.fieldName || ""] || ""}
                  onChange={(e) => {
                    setState({
                      ...state,
                      [v.fieldName || ""]: e.target.value,
                    });
                  }}
                />
              );
            }
            return v.options ? (
              <Fragment key={i}>
                <Select
                  key={i}
                  value={other ? "Other" : state[v.fieldName || ""] || ""}
                  onValueChange={(e) => {
                    if (e === "Other") {
                      setOther(true);
                      setState({
                        ...state,
                        [v.fieldName || ""]: "",
                      });
                    } else {
                      setOther(false);
                      setState({
                        ...state,
                        [v.fieldName || ""]: e,
                      });
                    }
                  }}
                  name={v.fieldName || ""}
                  required={!!v.required}
                >
                  <SelectTrigger className="w-full  border-foreground/40 bg-background-2/50 mt-4">
                    <SelectValue
                      placeholder={
                        <span className="!text-button pl-1">
                          {(v.placeholder || "") + (v.required ? "*" : "")}
                        </span>
                      }
                    />
                  </SelectTrigger>
                  <SelectContent className="!text-button border-foreground/40 bg-background-2 m-0 p-0">
                    {v.options
                      .filter((d) => !!d)
                      .map((d, i) => (
                        <SelectItem key={i} value={d!} className="!text-button">
                          <span className="!text-button">{d}</span>
                        </SelectItem>
                      ))}
                    {v.withOtherOption && (
                      <SelectItem value="Other" className="!text-button">
                        <span className="!text-button">Other</span>
                      </SelectItem>
                    )}
                  </SelectContent>
                </Select>
                {other && (
                  <Input
                    key={i}
                    placeholder={
                      (v.placeholder || "") + (v.required ? "*" : "")
                    }
                    type={v.fieldType || "text"}
                    id={v.fieldName || ""}
                    name={v.fieldName || ""}
                    required={!!v.required}
                    value={state[v.fieldName || ""] || ""}
                    onChange={(e) => {
                      setState({
                        ...state,
                        [v.fieldName || ""]: e.target.value,
                      });
                    }}
                  />
                )}
              </Fragment>
            ) : (
              <Input
                key={i}
                placeholder={(v.placeholder || "") + (v.required ? "*" : "")}
                type={v.fieldType || "text"}
                id={v.fieldName || ""}
                name={v.fieldName || ""}
                required={!!v.required}
                value={state[v.fieldName || ""] || ""}
                onChange={(e) => {
                  setState({ ...state, [v.fieldName || ""]: e.target.value });
                }}
              />
            );
          })}

          <Button
            style={{ marginBottom: "1rem" }}
            type="submit"
            data-tina-field={tinaField(data, "buttonText")}
            disabled={!!status}
          >
            {data.buttonText || "Submit"}
          </Button>
          <Text
            t="caption"
            c="text/secondary"
            mt="2rem"
            data-tina-field={tinaField(data, "disclaimer")}
          >
            <Markdown>{data.disclaimer}</Markdown>
          </Text>
          {status === "success" && (
            <Text
              t="button"
              as="p"
              css={`
                background-color: rgba(255, 255, 255, 0.1);
                border: 1px solid rgba(62, 255, 65, 0.3);
                padding: 1rem;
                color: rgba(62, 255, 65, 0.8);
                text-align: center;
                width: 100%;
              `}
            >
              Thanks for contacting us!
            </Text>
          )}
          {status === "error" && (
            <Text
              t="button"
              as="p"
              css={`
                background-color: rgba(255, 255, 255, 0.1);
                border: 1px solid rgba(62, 110, 255, 0.3);
                padding: 1rem;
                color: rgba(62, 110, 255, 0.8);
                text-align: center;
                width: 100%;
              `}
            >
              Sorry, we could not reach our servers. <br /> Please try again
              later.
            </Text>
          )}
        </>
      </form>
    </div>
  );
}

type FormRowProps = {
  placeholder: string;
  type: string;
  name: string;
  required?: boolean;
};
