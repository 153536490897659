import {
  Answer,
  Question,
  QuestionBox,
} from "@/components/Blocks/FAQ/FAQ4/styles";
import Markdown from "@/components/shared/Markdown";
import { SafeLink } from "@/components/shared/SafeLink";
import { Divider, Text } from "@/components/shared/Text";
import { DesktopOnly, TabletDown } from "@/lib";
import { useRouter } from "next/router";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import {
  GlobalPartsFragment,
  LayoutHeaderHeader1,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import ArrowRight from "../../../../public/icons/arrow_right.svg";
import LogoMobile from "../../../../public/icons/Logo-mobile.svg";
import LogoDesktop from "../../../../public/icons/OPT_Industries_logo_v2.svg";
import { Column } from "../../Footer/Footer4/styles";
import {
  Button,
  Container,
  Links,
  Logo,
  MenuItem,
  SidePanel,
  StyledImage,
  StyledLink,
  SubMenuContainer,
  SubMenuItem,
} from "./styles";

export const getArrowString = (name?: string | undefined | null) => {
  return (
    <>
      {name?.endsWith("->") ? (
        <>
          {name.replace("->", "")}

          <ArrowRight
            viewBox="0 0 48 48"
            css={`
              margin-left: 2px;
              height: 1rem;
              width: 1.2rem;
            `}
          />
        </>
      ) : (
        name
      )}
    </>
  );
};

export default function Header1({
  data,
  global,
  pageId,
}: {
  data: LayoutHeaderHeader1;
  global?: GlobalPartsFragment;
  pageId?: string;
}) {
  const [y, setY] = useState(0);
  const [visible, setVisible] = useState(true);
  const [menuField, setMenuField] = useState<string | null | undefined>(null);
  const [menuSubField, setMenuSubField] = useState<string | null | undefined>(
    null
  );
  const [sidepanel, setSidepanel] = useState(false);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;
      if (y < 2) {
        setVisible(true);
      } else if (y > window.scrollY) {
        setVisible(true);
      } else if (y < window.scrollY) {
        setVisible(false);
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const theme = useTheme();
  const router = useRouter();

  return (
    <div>
      <Container
        data-tina-field={tinaField(data)}
        color={data.backgroundColor || undefined}
        $position={data.position || "fixed"}
        onMouseLeave={() => {
          setMenuField(null);
          setMenuSubField(null);
        }}
        global={global}
        $visible={
          visible ||
          !data.hide ||
          !(!data.position || data.position === "fixed")
        }
      >
        <DesktopOnly>
          <SubMenuContainer
            $visible={!!menuField}
            color={data.backgroundColor || undefined}
          >
            <Column style={{ visibility: "hidden" }}>
              {(
                [...(data?.links || [])]?.sort(
                  (b, a) =>
                    (a?.subMenuFields?.length || 0) -
                    (b?.subMenuFields?.length || 0)
                )?.[0]?.subMenuFields || []
              )?.map((d, n) => (
                <StyledLink key={n} style={{ paddingTop: "0.5rem" }}>
                  {d?.name}
                </StyledLink>
              ))}
            </Column>
          </SubMenuContainer>
        </DesktopOnly>
        <TabletDown>
          <SafeLink href={"/"}>
            <div
              css={`
                height: 57px;
                width: 57px;
                display: flex;
                margin-left: -10px;
              `}
            >
              <LogoMobile
                alt="OPT Industries Logo"
                width="57"
                height="57"
                css={`
                  width: 100%;
                  height: 100%;
                `}
              />
            </div>
          </SafeLink>
        </TabletDown>
        <Links>
          <DesktopOnly>
            <SafeLink
              href={"/"}
              css={`
                height: 28px;
                width: 175px;
                display: flex;
                margin-right: 4rem;
              `}
            >
              <LogoDesktop alt="OPT Industries Logo" width="175" height="28" />
            </SafeLink>
          </DesktopOnly>

          <DesktopOnly>
            <div
              style={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                minHeight: menuField ? 400 : 80,
                transition: "min-height 1s cubic-bezier(0.19, 1, 0.22, 1)",
              }}
            >
              {(data.links || []).map((v, i) => (
                <Fragment key={i}>
                  <MenuItem
                    onMouseEnter={() => {
                      setMenuField(v?.name);
                    }}
                  >
                    <SafeLink href={v?.url}>
                      <StyledLink
                        data-tina-field={v && tinaField(v)}
                        style={{ whiteSpace: "nowrap" }}
                        onMouseEnter={() => {
                          setMenuField(v?.name);
                          setMenuSubField(null);
                        }}
                        onClick={() => {
                          setMenuField(null);
                          setMenuSubField(null);
                        }}
                      >
                        {v?.name}
                      </StyledLink>
                    </SafeLink>
                    <SubMenuItem
                      $visible={data?.links?.[i]?.name === menuField}
                      $submenu={!!menuField}
                    >
                      <Column style={{ maxWidth: "160px" }}>
                        {(v?.subMenuFields || []).map((d, n) => (
                          <SafeLink key={n} href={d?.url}>
                            <StyledLink
                              data-tina-field={v && tinaField(v)}
                              onClick={() => {
                                setMenuField(null);
                                setMenuSubField(null);
                              }}
                              onMouseEnter={() => {
                                setMenuSubField(d?.name);
                              }}
                              style={{
                                paddingTop: "0.5rem",
                                color: theme.colors["text/secondary"],
                              }}
                            >
                              {d?.name}
                            </StyledLink>
                          </SafeLink>
                        ))}
                      </Column>
                      <div
                        style={{
                          width: 260,
                          height: 160,
                          objectFit: "cover",
                          position: "relative",
                          paddingTop: "0.75rem",
                        }}
                      >
                        {[
                          {
                            name: v?.name,
                            url: v?.url,
                            description: v?.description,
                            image: v?.image,
                          },
                          ...(v?.subMenuFields || []),
                        ].map((d, n) => (
                          <div
                            key={n + (d?.name || "")}
                            onClick={() => {
                              if (
                                d?.name === menuSubField ||
                                (!menuSubField && d?.name === menuField)
                              ) {
                                router.push(d?.url || "");
                              }
                              setMenuField(null);
                              setMenuSubField(null);
                            }}
                            style={{
                              display:
                                d?.name === menuSubField ||
                                (!menuSubField && d?.name === menuField)
                                  ? "block"
                                  : "none",
                              width: 260,
                              height: 160,
                              objectFit: "cover",
                              position: "relative",
                              cursor: "pointer",
                            }}
                          >
                            <StyledImage
                              $visible={
                                d?.name === menuSubField ||
                                (!menuSubField && d?.name === menuField)
                              }
                              width="260"
                              height="160"
                              priority
                              src={d?.image || "/dummy.png"}
                              alt={d?.name || ""}
                            />
                          </div>
                        ))}
                      </div>
                      <div
                        style={{
                          position: "relative",
                          marginLeft: "1rem",
                          paddingTop: "0.5rem",
                        }}
                      >
                        {[
                          {
                            name: v?.name,
                            url: v?.url,
                            description: v?.description,
                            image: v?.image,
                          },
                          ...(v?.subMenuFields || []),
                        ].map((d, n) => (
                          <div
                            key={n + (d?.name || "")}
                            onClick={() => {
                              if (
                                d?.name === menuSubField ||
                                (!menuSubField && d?.name === menuField)
                              ) {
                                router.push(d?.url || "");
                              }

                              setMenuField(null);
                              setMenuSubField(null);
                            }}
                            style={{
                              display:
                                d?.name === menuSubField ||
                                (!menuSubField && d?.name === menuField)
                                  ? "flex"
                                  : "none",
                              width: 260,
                              height: 160,
                              objectFit: "cover",
                              position: "relative",
                              justifyContent: "end",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <Text
                              t="caption"
                              mb="1rem"
                              c="text/secondary"
                              css={`
                                text-align: start;
                              `}
                            >
                              {d?.name}
                            </Text>
                            <Text
                              t="button"
                              as="div"
                              css={`
                                min-height: 50%;
                                text-align: start;
                              `}
                            >
                              <Markdown>{d?.description}</Markdown>
                            </Text>
                          </div>
                        ))}
                      </div>
                    </SubMenuItem>
                  </MenuItem>
                </Fragment>
              ))}
            </div>
          </DesktopOnly>
        </Links>
        <DesktopOnly>
          <Links
            css={`
              display: flex;
              flex: 1 1 auto;
              height: 100%;
              overflow: hidden;
              position: relative;
            `}
          >
            {(data.buttons || []).map((v, i) => (
              <SafeLink
                key={i}
                href={
                  v?.overrides?.find((d) => d?.page?.id === pageId)?.url ||
                  v?.url
                }
                css={`
                  display: flex;
                  flex: 1 1 auto;
                  position: relative;
                  width: 100%;
                `}
              >
                <Button
                  data-tina-field={v && tinaField(v)}
                  css={`
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 1 1 auto;
                    margin: 0;
                    padding: 0 min(20%, 2rem);
                    justify-content: start;
                    width: 100%;
                    max-width: fit-content;
                    margin-left: auto;

                    display: block;
                    svg {
                      transform: translate(0, 3px);
                    }
                  `}
                >
                  {getArrowString(
                    v?.overrides?.find((d) => d?.page?.id === pageId)?.name ||
                      v?.name
                  )}
                </Button>
              </SafeLink>
            ))}
          </Links>
        </DesktopOnly>
        <TabletDown>
          <div style={{ zIndex: 101, display: "flex" }}>
            <Logo
              src="/icons/burger.svg"
              alt="Open side menu"
              onClick={() => setSidepanel(!sidepanel)}
              width="32"
              height="32"
              style={{
                height: 32,
                width: 32,
                transform: sidepanel ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </div>
        </TabletDown>
      </Container>
      <TabletDown>
        <SidePanel open={sidepanel}>
          {data.links?.map((v, i) => (
            <QuestionBox
              data-tina-field={v && tinaField(v)}
              key={i}
              onClick={() => {
                if (v?.subMenuFields && v.subMenuFields.length) {
                  if (menuField === v?.name) {
                    setMenuField(null);
                  } else {
                    setMenuField(v?.name);
                  }
                }
              }}
            >
              {v?.subMenuFields && v.subMenuFields.length ? (
                <Question>
                  <Text
                    t="headline3"
                    data-tina-field={v && tinaField(v, "name")}
                    m="0"
                  >
                    {v?.name}
                  </Text>
                  <img
                    alt="Open submenu"
                    width="24"
                    height="24"
                    src="/icons/chevron-down.svg"
                    style={{
                      transform:
                        menuField === v?.name ? "scaleY(-1)" : "scaleY(1)",
                    }}
                  />
                </Question>
              ) : (
                <SafeLink href={v?.url || ""}>
                  <Question onClick={() => setSidepanel(false)}>
                    <Text
                      t="headline3"
                      data-tina-field={v && tinaField(v, "name")}
                      m="0"
                    >
                      {v?.name}
                    </Text>
                    <img
                      alt="Open submenu"
                      width="24"
                      height="24"
                      src="/icons/chevron-down.svg"
                      style={{
                        transform: "rotate(-90deg)",
                      }}
                    />
                  </Question>
                </SafeLink>
              )}
              {v?.subMenuFields && v.subMenuFields.length && (
                <Answer open={menuField === v?.name}>
                  <Column>
                    {!v?.subMenuFields?.find((f) => f?.name === v?.name) && (
                      <SafeLink href={v?.url}>
                        <StyledLink
                          onClick={() => setSidepanel(false)}
                          style={{
                            paddingTop: "0.5rem",
                            color: theme.colors["text/secondary"],
                          }}
                        >
                          Overview
                        </StyledLink>
                      </SafeLink>
                    )}
                    {(v?.subMenuFields || []).map((d, n) => (
                      <SafeLink key={n} href={d?.url}>
                        <StyledLink
                          onClick={() => setSidepanel(false)}
                          data-tina-field={d && tinaField(d)}
                          style={{
                            paddingTop: "0.5rem",
                            color: theme.colors["text/secondary"],
                          }}
                        >
                          {d?.name}
                        </StyledLink>
                      </SafeLink>
                    ))}
                  </Column>
                  <br />
                  <br />
                </Answer>
              )}
              <Divider c="elements/gray-1" mt="0" mb="0" />
            </QuestionBox>
          ))}
          <Links>
            {(data.buttons || []).map((v, i) => (
              <SafeLink
                key={i}
                href={
                  v?.overrides?.find((d) => d?.page?.id === pageId)?.url ||
                  v?.url ||
                  ""
                }
                onClick={() => {
                  setSidepanel(false);
                }}
              >
                <Button data-tina-field={v && tinaField(v)}>
                  {getArrowString(
                    v?.overrides?.find((d) => d?.page?.id === pageId)?.name ||
                      v?.name
                  )}
                </Button>
              </SafeLink>
            ))}
          </Links>
        </SidePanel>
      </TabletDown>
    </div>
  );
}
Header1.blockName = "LayoutHeaderHeader1";
