import Link, { LinkProps } from "next/link";
import { HTMLAttributeAnchorTarget, ReactNode } from "react";
export function SafeLink({
  target = undefined,
  ...props
}: (
  | LinkProps
  | (Omit<LinkProps, "href"> & ({ href?: undefined } | { href: null }))
) & {
  children: ReactNode | ReactNode[];
  className?: string;
  target?: HTMLAttributeAnchorTarget;
}) {
  return props?.href !== undefined && props?.href !== null && !!props.href ? (
    <Link {...props} target={target}>
      {props.children}
    </Link>
  ) : (
    <div className={props.className || undefined}>{props.children}</div>
  );
}
