import styled, { css } from "styled-components";

import { desktop, mobile, tablet, theme } from "@/lib";
import { Theme } from "@/lib/types/styled";
import {
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  flexbox,
  layout,
  position,
  space,
} from "styled-system";

export type colorTypes = keyof ReturnType<typeof theme>["colors"];
export type fontTypes = keyof ReturnType<typeof theme>["font"];

const colors: colorTypes[] = Object.keys(theme().colors) as colorTypes[];

type TextParams = {
  /** Typography type */
  t?: fontTypes;
  /** Typography type for mobile */
  tm?: fontTypes;
  /** Typography type for desktop */
  td?: fontTypes;
  /** Typography type for tablet */
  tt?: fontTypes;
  /** Typography color */
  c?: colorTypes;
} & SpaceProps &
  FlexboxProps &
  LayoutProps &
  PositionProps & { theme: Theme };

const getColorAndStyle = (p: TextParams) => css`
  /* PRIMARY SETUP */
  // Here set font color
  ${p.c
    ? colors.includes(p.c)
      ? css`
          color: ${p.theme.colors[p.c]};
        `
      : css`
          color: ${p.c};
        `
    : css`
        color: ${p.theme.colors["text/primary"]};
      `}
  // Here set font styling
  ${p.theme.font[p.t || "body"]}
  ${p.td && desktop(p.theme.font[p.td || "body"])}
  ${p.tt && tablet(p.theme.font[p.tt || "body"])}
  ${p.tm && mobile(p.theme.font[p.tm || "body"])}
`;

export const Text = styled.span.attrs(
    ({
      t,
      tt,
      tm,
      td,
      as,
    }: {
      t?: fontTypes;
      tt?: fontTypes;
      tm?: fontTypes;
      td?: fontTypes;
      as?: string;
    }) =>
      !!as
        ? { as }
        : t === "button"
        ? { as: "button" }
        : t === "headline1"
        ? { as: "h1" }
        : t === "headline2"
        ? { as: "h2" }
        : t === "headline3"
        ? { as: "h3" }
        : t === "headline4"
        ? { as: "h4" }
        : t === "display1"
        ? { as: "h1" }
        : t === "display2"
        ? { as: "h1" }
        : t === "display3"
        ? { as: "h1" }
        : {}
  )<TextParams>`
    ${(p) => getColorAndStyle(p)}
    a, p {
      ${(p) => getColorAndStyle(p)}
    }

    // Here configure button
    ${(p) =>
      p.t === "button" &&
      css`
        cursor: pointer;
        border: none;
        background: none;
        outline: none;
      `}

    /* SECONDARY SETUP */
    // This is to prevent weird spacing between lines, but not sure how it works
    ${(p) =>
      !(
        p.t === "body" ||
        p.t === "caption" ||
        p.t === "button" ||
        p.t === "uppercase"
      ) &&
      css`
        > p {
          margin: 0;
          padding: 0;
        }
      `}

    // This is to make newlines actually work
    white-space: pre-wrap;

    // This is for possible svg injections
    > svg {
      margin-right: 8px;
    }

    // This is some special case
    > :first-child {
      margin-top: 0;
    }

    ${space}
    ${layout}
    ${position}
    ${flexbox}
  `,
  Divider = styled.div<
    { c?: colorTypes } & SpaceProps & FlexboxProps & LayoutProps & PositionProps
  >`
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
    position: relative;
    margin-bottom: 1rem;
    ${(p) =>
      p.c
        ? colors.includes(p.c)
          ? css`
              border-top: 1px solid ${p.theme.colors[p.c]};
            `
          : css`
              border-top: 1px solid ${p.c};
            `
        : css`
            border-top: ${p.theme.colors["text/primary"]};
          `}
    ${space}
  ${layout}
  ${position}
  ${flexbox}
  `;
